// import React, { useState, useContext } from 'react'
// import axios from 'axios'
// import AuthContext from '../AuthContext'
// import '../styles/Auth.css'

// // const BASE_URL = "https://app.modinsight.ai:8085/v2/";
// const BASE_URL = 'https://modinsight.dev:8089/'

// export default function SignUp() {
//   const [email, setEmail] = useState('')
//   const [confirmEmail, setConfirmEmail] = useState('')
//   const [password, setPassword] = useState('')
//   const [confirmPassword, setConfirmPassword] = useState('')
//   const [error, setError] = useState('')

//   const { login } = useContext(AuthContext)

//   const handleSubmit = async (e) => {
//     e.preventDefault()
//     if (email !== confirmEmail) {
//       setError('Emails do not match')
//       return
//     }
//     if (email.endsWith('@gmail.com')) {
//       setError('Email cannot be @gmail.com')
//       return
//     }
//     if (password !== confirmPassword) {
//       setError('Passwords do not match')
//       return
//     }

//     try {
//       const response = await axios.post(`${BASE_URL}v1/user/register`, {
//         email,
//         confirm_email: confirmEmail,
//         password,
//         confirm_password: confirmPassword,
//       })
//       console.log(response)
//       if (response.data.status === 1) {
//         const token = response.data.data.token
//         login(email, token)
//       } else {
//         setError(response.data.message)
//       }
//     } catch (err) {
//       setError('Failed to sign up')
//     }
//   }

//   return (
//     <div className="auth-container">
//       <h2>Sign Up</h2>
//       {error && <p className="error">{error}</p>}
//       <form onSubmit={handleSubmit}>
//         <div className="form-group">
//           <label>Email:</label>
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Confirm Email:</label>
//           <input
//             type="email"
//             value={confirmEmail}
//             onChange={(e) => setConfirmEmail(e.target.value)}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>fname:</label>
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>lname:</label>
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Companyname:</label>
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Password:</label>
//           <input
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Confirm Password:</label>
//           <input
//             type="password"
//             value={confirmPassword}
//             onChange={(e) => setConfirmPassword(e.target.value)}
//             required
//           />
//         </div>
//         <button type="submit">Sign Up</button>
//       </form>
//     </div>
//   )
// }

import React, { useState, useContext } from 'react'
import axios from 'axios'
import AuthContext from '../AuthContext'
import '../styles/Auth.css'

// const BASE_URL = "https://app.modinsight.ai:8085/v2/";
const BASE_URL = 'https://modinsight.dev:8089/v1'

export default function SignUp() {
  const [email, setEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [fname, setFname] = useState('')
  const [lname, setLname] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [error, setError] = useState('')

  const { login } = useContext(AuthContext)

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (email !== confirmEmail) {
      setError('Emails do not match')
      return
    }
    if (email.endsWith('@gmail.com')) {
      setError('Email cannot be @gmail.com')
      return
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match')
      return
    }

    try {
      const response = await axios.post(`${BASE_URL}/user/register`, {
        email,
        confirm_email: confirmEmail,
        password,
        confirm_password: confirmPassword,
        fname,
        lname,
        company_name: companyName,
      })
      console.log(response)
      if (response.data.status === 1) {
        const token = response.data.data.token
        login(email, token)
      } else {
        setError(response.data.message)
      }
    } catch (err) {
      setError('Failed to sign up')
    }
  }

  return (
    <div className="auth-container">
      <h2>Sign Up</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="side-by-side">
            <div className="half-width">
              <label>First Name:</label>
              <input
                type="text"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                required
              />
            </div>
            <div className="half-width">
              <label>Last Name:</label>
              <input
                type="text"
                value={lname}
                onChange={(e) => setLname(e.target.value)}
                required
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>Company Name:</label>
          <input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Confirm Email:</label>
          <input
            type="email"
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Confirm Password:</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Sign Up</button>
      </form>
    </div>
  )
}
