import React, { useContext, useState } from 'react'
import './styles/App.css'
import logo from './img/logo.png'
import Home from './components/Home'
import NewRequest from './components/NewRequest'
import CompletedAnalyses from './components/CompletedAnalyses'
import LegalConciergeRep from './components/LegalConciergeRep'
import AuthContext, { AuthProvider } from './AuthContext'
import ErrorBoundary from './components/ErrorBoundary'
import Auth from './components/Auth'

function AppContent() {
  const { isAuthenticated, logout } = useContext(AuthContext)
  const [selectedTab, setSelectedTab] = useState('Home')

  const renderContent = () => {
    switch (selectedTab) {
      case 'Home':
        return <Home />
      case 'NewRequest':
        return <NewRequest />
      case 'CompletedAnalyses':
        return <CompletedAnalyses />
      case 'LegalConciergeRep':
        return <LegalConciergeRep />
      default:
        return <Home />
    }
  }

  if (!isAuthenticated) {
    return (
      <div className="auth-container">
        <Auth />
      </div>
    )
  }

  return (
    <div className="App">
      <div className="sidenav">
        <img src={logo} alt="modInsight Logo" />
        <button id="Home" onClick={() => setSelectedTab('Home')}>
          Home
        </button>
        <button id="NewRequest" onClick={() => setSelectedTab('NewRequest')}>
          Submit New Request
        </button>
        <button
          id="CompletedAnalyses"
          onClick={() => setSelectedTab('CompletedAnalyses')}
        >
          Completed Analyses
        </button>
        <button
          id="LegalConciergeRep"
          onClick={() => setSelectedTab('LegalConciergeRep')}
        >
          Legal Concierge Reporting
        </button>
        <button className="logout-button" onClick={logout}>
          Log Out
        </button>
      </div>
      <div className="content">{renderContent()}</div>
    </div>
  )
}

function App() {
  return (
    <AuthProvider>
      <ErrorBoundary>
        <AppContent />
      </ErrorBoundary>
    </AuthProvider>
  )
}

export default App