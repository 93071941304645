import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import AuthContext from '../AuthContext'
import '../styles/CompletedAnalyses.css'

// const BASE_URL = "https://app.modinsight.ai:8085/v2/";
const BASE_URL = 'https://modinsight.dev:8089/v1'

export default function CompletedAnalyses() {
  const [files, setFiles] = useState([])
  const [error, setError] = useState('')
  const { userToken } = useContext(AuthContext)

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}user/file/list`,
          {
            page: '0',
            size: '10',
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        )

        console.log('API Response:', response)

        if (response.data.status === 1) {
          setFiles(response.data.data)
        } else {
          setError(response.data.message)
        }
      } catch (error) {
        console.error('Error fetching files:', error)
        setError('Failed to fetch files')
      }
    }

    fetchFiles()
  }, [userToken])

  return (
    <div className="completed-analyses">
      <table className="file-table">
        <thead>
          <tr>
            <th>File Name</th>
            <th>Date/Time Submitted</th>
            <th>Date/Time Completed</th>
            <th>Buyer/Seller</th>
            <th>Assessment Performed</th>
            <th>Overall Risk Score</th>
            <th>Download Analysis</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <tr key={file._id}>
              <td>{file.filename}</td>
              <td>{new Date(file.added_date).toLocaleString()}</td>
              <td>
                {file.completedDateTime
                  ? new Date(file.completedDateTime).toLocaleString()
                  : 'N/A'}
              </td>
              <td>{file.buyerSeller || 'N/A'}</td>
              <td>{file.assessmentPerformed || 'N/A'}</td>
              <td>{file.score || 'N/A'}</td>
              <td>
                <input
                  type="checkbox"
                  checked={file.download || false}
                  readOnly
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {error && <p className="error">{error}</p>}
    </div>
  )
}
