// import React, { useState, useContext } from 'react'
// import axios from 'axios'
// import AuthContext from '../AuthContext'
// import '../styles/Auth.css'

// // const BASE_URL = "https://app.modinsight.ai:8085/v2/";
// const BASE_URL = 'https://modinsight.dev:8089/v1'

// export default function SignIn() {
//   const [email, setEmail] = useState('')
//   const [password, setPassword] = useState('')
//   const [error, setError] = useState('')

//   const { login } = useContext(AuthContext)

//   const handleSubmit = async (e) => {
//     e.preventDefault()

//     try {
//       const response = await axios.post(`${BASE_URL}/user/login`, {
//         email,
//         password,
//       })
//       if (response.data.status === 1) {
//         const token = response.data.data.token
//         login(email, token)
//       } else {
//         setError(response.data.message)
//       }
//     } catch (err) {
//       setError('Failed to sign in')
//     }
//   }

//   return (
//     <div className="auth-container">
//       <h2>Sign In</h2>
//       {error && <p className="error">{error}</p>}
//       <form onSubmit={handleSubmit}>
//         <div className="form-group">
//           <label>Email:</label>
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Password:</label>
//           <input
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//           />
//         </div>
//         <button type="submit">Sign In</button>
//       </form>
//     </div>
//   )
// }

import React, { useState, useContext } from 'react'
import axios from 'axios'
import AuthContext from '../AuthContext'
import '../styles/Auth.css'

// const BASE_URL = "https://app.modinsight.ai:8085/v2/";
const BASE_URL = 'https://modinsight.dev:8089/v1'

export default function SignIn() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const { login } = useContext(AuthContext)

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.post(`${BASE_URL}/user/login`, {
        email,
        password,
      })
      if (response.data.status === 1) {
        const { token, role } = response.data.data
        console.log(role)
        login(email, token, role)
      } else {
        setError(response.data.message)
      }
    } catch (err) {
      setError('Failed to sign in')
    }
  }

  return (
    <div className="auth-container">
      <h2>Sign In</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Sign In</button>
      </form>
    </div>
  )
}
