import React, { createContext, useState, useEffect } from 'react'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    JSON.parse(localStorage.getItem('isAuthenticated')) || false
  )
  const [userEmail, setUserEmail] = useState(
    localStorage.getItem('userEmail') || ''
  )
  const [userToken, setUserToken] = useState(
    localStorage.getItem('userToken') || ''
  )
  const [userRole, setUserRole] = useState(
    localStorage.getItem('userRole') || ''
  )

  useEffect(() => {
    localStorage.setItem('isAuthenticated', JSON.stringify(isAuthenticated))
  }, [isAuthenticated])

  useEffect(() => {
    localStorage.setItem('userEmail', userEmail)
  }, [userEmail])

  useEffect(() => {
    localStorage.setItem('userToken', userToken)
  }, [userToken])

  useEffect(() => {
    localStorage.setItem('userRole', userRole)
  }, [userRole])

  const login = (email, token, role) => {
    setIsAuthenticated(true)
    setUserEmail(email)
    setUserToken(token)
    setUserRole(role)
  }

  const logout = () => {
    setIsAuthenticated(false)
    setUserEmail('')
    setUserToken('')
    setUserRole('')
  }

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, userEmail, userToken, userRole, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
