// import React, { useEffect, useState, useContext } from 'react'
// import axios from 'axios'
// import AuthContext from '../AuthContext'
// import '../styles/Home.css'

// // const BASE_URL = "https://app.modinsight.ai:8085/v2/";
// const BASE_URL = 'https://modinsight.dev:8089/v1'

// export default function Home() {
//   const [files, setFiles] = useState([])
//   const [error, setError] = useState('')
//   const { userToken } = useContext(AuthContext)

//   useEffect(() => {
//     const fetchFiles = async () => {
//       try {
//         const response = await axios.post(
//           `${BASE_URL}/user/file/list`,
//           {
//             page: '0',
//             size: '10',
//           },
//           {
//             headers: {
//               Authorization: `Bearer ${userToken}`,
//               'Content-Type': 'application/json',
//             },
//           }
//         )

//         console.log('API Response:', response)

//         if (response.data.status === 1) {
//           setFiles(response.data.data)
//         } else {
//           setError(response.data.message)
//         }
//       } catch (error) {
//         console.error('Error fetching files:', error)
//         setError('Failed to fetch files')
//       }
//     }

//     fetchFiles()
//   }, [userToken])

//   const handleCheckboxChange = (index, key) => {
//     const newFiles = [...files]
//     newFiles[index][key] = !newFiles[index][key]
//     setFiles(newFiles)
//     //maybe persist change to server
//   }

//   return (
//     <div className="home">
//       <div className="section">
//         <div className="section-head">In Queue</div>
//         <table className="file-table">
//           <thead>
//             <tr>
//               <th>FILE NAME</th>
//               <th>SUBMIT DATE</th>
//               <th>PREPARE REDLINE</th>
//               <th>DELETE SUBMISSION</th>
//             </tr>
//           </thead>
//           <tbody>
//             {files.map((file, index) => (
//               <tr key={file._id}>
//                 <td>{file.filename}</td>
//                 <td>{new Date(file.added_date).toLocaleString()}</td>
//                 <td>
//                   <input
//                     type="checkbox"
//                     checked={file.prepareRedline || false}
//                     onChange={() =>
//                       handleCheckboxChange(index, 'prepareRedline')
//                     }
//                   />
//                 </td>
//                 <td>
//                   <input
//                     type="checkbox"
//                     checked={file.deleteSubmission || false}
//                     onChange={() =>
//                       handleCheckboxChange(index, 'deleteSubmission')
//                     }
//                   />
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       <div className="section">
//         <div className="section-head">Under Review</div>
//         <table className="file-table">
//           <thead>
//             <tr>
//               <th>FILE NAME</th>
//               <th>SUBMIT DATE</th>
//               <th>Step 1: INITIAL REVIEW</th>
//               <th>Step 2: QUALITY ANALYSIS</th>
//               <th>Step 3: FINAL STAGES</th>
//             </tr>
//           </thead>
//           <tbody>
//             {files.map((file, index) => (
//               <tr key={file._id}>
//                 <td>{file.filename}</td>
//                 <td>{new Date(file.added_date).toLocaleString()}</td>
//                 <td>
//                   {file.initialReview || <div className="step-progress"></div>}
//                 </td>
//                 <td>
//                   {file.qualityAnalysis || (
//                     <div className="step-progress"></div>
//                   )}
//                 </td>
//                 <td>
//                   {file.finalStages || <div className="step-progress"></div>}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//       {error && <p className="error">{error}</p>}
//     </div>
//   )
// }

import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import AuthContext from '../AuthContext'
import '../styles/Home.css'

// const BASE_URL = "https://app.modinsight.ai:8085/v2/";
const BASE_URL = 'https://modinsight.dev:8089/v1'

export default function Home() {
  const [files, setFiles] = useState([])
  const [error, setError] = useState('')
  const { userToken, userRole } = useContext(AuthContext)

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/user/file/list`,
          {
            page: '0',
            size: '10',
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        )

        console.log('API Response:', response)

        if (response.data.status === 1) {
          setFiles(response.data.data)
        } else {
          setError(response.data.message)
        }
      } catch (error) {
        console.error('Error fetching files:', error)
        setError('Failed to fetch files')
      }
    }

    fetchFiles()
  }, [userToken])

  const handleCheckboxChange = (index, key) => {
    const newFiles = [...files]
    newFiles[index][key] = !newFiles[index][key]
    setFiles(newFiles)
    // maybe persist change to server
  }

  if (userRole === 'qa_admin') {
    return (
      <div className="qa-admin-home">
        <h2>QA Admin Home</h2>
        {/* Example QA admin table */}
        <div className="section">
          <div className="section-head">Turnbacks / QA</div>
          <table className="file-table">
            <thead>
              <tr>
                <th>Vendor Name</th>
                <th>Contract Name</th>
                <th>Contract Type</th>
                <th>Date Received</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {files.map((file, index) => (
                <tr key={file._id}>
                  <td>{file.vendorName}</td>
                  <td>{file.contractName}</td>
                  <td>{file.contractType}</td>
                  <td>{new Date(file.dateReceived).toLocaleDateString()}</td>
                  <td>
                    <button>Edit</button>
                    <button>Accept</button>
                    <button>Decline</button>
                    <button>Re-assign</button>
                    <button>Return to Client</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  return (
    <div className="home">
      <div className="section">
        <div className="section-head">In Queue</div>
        <table className="file-table">
          <thead>
            <tr>
              <th>FILE NAME</th>
              <th>SUBMIT DATE</th>
              <th>PREPARE REDLINE</th>
              <th>DELETE SUBMISSION</th>
            </tr>
          </thead>
          <tbody>
            {files.map((file, index) => (
              <tr key={file._id}>
                <td>{file.filename}</td>
                <td>{new Date(file.added_date).toLocaleString()}</td>
                <td>
                  <input
                    type="checkbox"
                    checked={file.prepareRedline || false}
                    onChange={() =>
                      handleCheckboxChange(index, 'prepareRedline')
                    }
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={file.deleteSubmission || false}
                    onChange={() =>
                      handleCheckboxChange(index, 'deleteSubmission')
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="section">
        <div className="section-head">Under Review</div>
        <table className="file-table">
          <thead>
            <tr>
              <th>FILE NAME</th>
              <th>SUBMIT DATE</th>
              <th>Step 1: INITIAL REVIEW</th>
              <th>Step 2: QUALITY ANALYSIS</th>
              <th>Step 3: FINAL STAGES</th>
            </tr>
          </thead>
          <tbody>
            {files.map((file, index) => (
              <tr key={file._id}>
                <td>{file.filename}</td>
                <td>{new Date(file.added_date).toLocaleString()}</td>
                <td>
                  {file.initialReview || <div className="step-progress"></div>}
                </td>
                <td>
                  {file.qualityAnalysis || (
                    <div className="step-progress"></div>
                  )}
                </td>
                <td>
                  {file.finalStages || <div className="step-progress"></div>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {error && <p className="error">{error}</p>}
    </div>
  )
}
