import React, { useState } from 'react';
import SignIn from './SignIn';
import SignUp from './SignUp';

export default function Auth() {
  const [isSignIn, setIsSignIn] = useState(true);

  return (
    <div>
      {isSignIn ? <SignIn /> : <SignUp />}
      <div style={{ display: 'flex', justifyContent: 'center'}}>
        <button
          style={{
            backgroundColor: 'transparent',
            color: '#3f51b5',
            border: 'none',
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: '16px',
          }}
          onClick={() => setIsSignIn(!isSignIn)}
        >
          {isSignIn ? "Don't have an account? Sign Up" : 'Already have an account? Sign In'}
        </button>
      </div>
    </div>
  );
}
